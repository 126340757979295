<template>
    <div class="realtor">
        <!-- 查询表单 -->
        <a-form-model ref="searchForm" layout="horizontal" :label-col="{xs:8}" :wrapper-col="{xs:16}">
            <a-row :gutter="8">
                <a-col :md="8" :xl="6" :xxl="6" :sm="12">
                    <a-form-model-item label="名称">
                        <a-input v-model="searchData.name" placeholder="名称"></a-input>
                    </a-form-model-item>
                </a-col>
                <a-col :md="8" :xl="6" :xxl="6" :sm="12">
                  <a-form-model-item label="活动时间">
                    <DateRange :startTime.sync="searchData.validityStart"
                               :endTime.sync="searchData.validityEnd"></DateRange>
                  </a-form-model-item>
                </a-col>
                <a-col :md="8" :xl="6" :xxl="6" :sm="12">
                  <a-form-model-item label="审核状态">
                    <DictSelect field="reviewStatus" :value.sync="searchData.authStatus" style="width: 100%"
                                placeholder="审核状态"></DictSelect>
                  </a-form-model-item>
                </a-col>
                <a-col :md="8" :xl="6" :xxl="6" :sm="12">
                  <a-form-model-item label="参与积分">
                    <DictSelect field="IntegralType" :value.sync="searchData.joinIntegral" style="width: 100%"
                                placeholder="审核状态"></DictSelect>
                  </a-form-model-item>
                </a-col>
                <a-col :md="8" :xl="6" :xxl="6" :sm="12">
                  <a-form-model-item label="参与折扣">
                    <DictSelect field="IntegralType" :value.sync="searchData.joinRebate" style="width: 100%"
                                placeholder="审核状态"></DictSelect>
                  </a-form-model-item>
                </a-col>
                <a-col :xxl="6" :xl="5" :md="8" sm="2">
                    <a-form-model-item :label-col="{span: 0}" :wrapper-col="{span:24}">
                        <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
                        <a-button type="default" @click="reset()" icon="sync">重置</a-button>
                    </a-form-model-item>
                </a-col>
            </a-row>
        </a-form-model>
        <div>
            <a-button type="primary" @click="toHandler('add')">新增</a-button>
            <a-button type="primary" @click="toHandler('edit')">修改</a-button>
            <a-button type="primary" @click="toHandler('del')">删除</a-button>
        </div>
        <!-- 数据表格 -->
        <a-table :loading="tableLoading" :row-selection="rowSelection" :rowKey="record => record.id"
           :columns="columns" :data-source="tableData" bordered :pagination="page"
           @change="changeTable" :customRow="changeTableRow">
          <span slot="joinRebate" slot-scope="text">{{text ? '是' : '否'}}</span>
          <span slot="joinIntegral" slot-scope="text">{{text ? '是' : '否'}}</span>
          <span slot="authStatus" slot-scope="text">
            <span v-if="text == 1">未处理</span>
            <span v-if="text == 2">待审核</span>
            <span v-if="text == 3">审核通过</span>
            <span v-if="text == 4">拒绝</span>
          </span>
        </a-table>
        <!-- 编辑表单 -->
        <PolicyInfo-edit-modal ref="PolicyInfoEditModal" @reload="getData"></PolicyInfo-edit-modal>
    </div>
</template>

<script>
import { columns } from './components/colums.js'
import PolicyInfoEditModal from './components/PolicyInfoEditModal.vue'
import { delPolicyInfo, listPolicyInfo, selectByIdPolicyInfo } from './api/PolicyInfoApi'

const pageSource = {
    current: 1,
    pageSize: 10,
    total: 0
}
export default {
    name: 'policyInfo',
    components: {
        PolicyInfoEditModal
    },
    data() {
        return {
            columns: columns,
            tableData: [],
            searchData: {}, // 搜索条件
            scroll:{ y: 600 },
            tableLoading: false, //表格loading
            page: Object.assign({}, pageSource),
            selectedRowKeys: [],
            selectedRows: []
        }
    },
    computed: {
        rowSelection() {
            const _this = this
            return {
                fixed: true,
                type: 'radio', //"radio"
                selectedRowKeys: this.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    _this.selectedRowKeys = selectedRowKeys
                    _this.selectedRows = selectedRows
                }
            }
        }
    },
    methods:{
        /**
         * 获取表格数据
         */
        getData() {
            this.tableLoading = true
            listPolicyInfo ({
                pageNumber: this.page.current,
                pageSize: this.page.pageSize,
                ...this.searchData
            }).then(res => {
                if (res.code === 200) {
                    const { total, records } = res.body
                    this.page.total = total
                    this.tableData = records
                }
            }).finally(() => this.tableLoading = false)
        },
        /**
         * 筛选查询
         */
        getDataFilter() {
            this.page = Object.assign({}, pageSource)
            this.getData()
        },
        /**
         * 重置
         */
        reset() {
            this.searchData = {}
            this.page = Object.assign({}, pageSource)
            this.getData()
        },
        /**
         * 更改分页
         */
        changeTable(pagination) {
            this.page = pagination
            this.getData()
        },
        /**
         * 点击行选中
         */
        changeTableRow(record) {
            return {
                on: {
                    click: e => {
                        console.log(e)
                        if (this.selectedRowKeys[0] === record.id) {
                            this.selectedRowKeys = []
                            this.selectedRows = []
                        } else {
                            this.selectedRowKeys = [record.id]
                            this.selectedRows = [record]
                        }
                    }
                }
            }
        },
        toHandler(name) {
            const _this = this
            if (name === 'add') {
                _this.$refs.PolicyInfoEditModal.setRowData({}, 'add')
                return
            }
            if (_this.selectedRows.length === 0) {
                _this.$notification.warning({
                    message: '请选择一条记录'
                })
                return
            }
            switch (name) {
                case 'edit':
                    _this.$refs.PolicyInfoEditModal.setRowData(_this.selectedRows[0], 'edit')
                    break
                case 'del':
                    _this.$confirm({
                        title: '删除警告',
                        content: '确认要删除此条记录吗?',
                        okText: '确认',
                        cancelText: '取消',
                        async onOk() {
                            const res = await delPolicyInfo(_this.selectedRows[0].id)
                            if (res.code === 200) {
                                _this.$notification.success({ message: res.message })
                                _this.getData()
                            } else {
                                _this.$notification.error({ message: res.message })
                            }
                        },
                        onCancel() {}
                    })
                    break
            }
        },
    },
    created() {
        this.getData()
    }
}
</script>

<style lang="less" scoped>
    .ant-btn {
        margin-right: 8px;
        margin-bottom: 12px;
    }
</style>
